import React from "react";
import logo from "../../../assets/logo.svg";
import "./Header.css";
import Cta from "../../partials/Cta/Cta";

import { Animated } from "react-animated-css";

import { Anchor } from "antd";
const { Link } = Anchor;

const Header = (props) => {

  return (
    <div className="navbar-container">
      <div className="logo-container">
        <img className="logo" src={logo} alt="" />
      </div>
      <Animated
        animationIn="bounce"
        animationInDelay={1500}
        animationInDuration={1000}
      >
        <Anchor>
          <Link href="#waitlist">
            <Cta text="Request a demo" className="cta" />
          </Link>
        </Anchor>
      </Animated>
    </div>
  );
};

export default Header;
