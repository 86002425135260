import React from "react";
import smile from "../../../assets/smile.svg";
import "./Cta.css";

const Cta = (props) => {
  return (
    <a href="#waitlist" className="waitlist-cointainer">
      <div className="waitlist-text">{props.text}</div>
      <img className="bell" src={smile} alt="join waitlist button " />
    </a>
  );
};

export default Cta;
