import React from "react";
import plateBg from "../../../assets/feature-two.png";

import "./Plate.css";
import RellaxWrapper from "react-rellax-wrapper";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Plate = (props) => {
  let isDesktop = props.isDesktop;
  let textContainerSpeed = isDesktop ? "-1" : "0";
  let backgroundSpeed = isDesktop ? "2" : "0";
  let towerSpeed = isDesktop ? "-1.7" : "0";

  return (
    <div className="plate-container">
      <div className="plate-text-container">
        <RellaxWrapper speed={textContainerSpeed} percentage="0">
          <div className="section-header">
          Traditional ordering experience.
          </div>
          <div className="section-sub-header">
          Let your staff take care of the orders.
          </div>
          <div className="plate-text">
          Excellent customer service will improve the relationship between your guests and your business. 
          Moreover, satisfied guests are more likely to come back and be loyal to your restaurant.
          </div>
        </RellaxWrapper>
      </div>
      <div className="plate-image-mask">
        <RellaxWrapper speed={backgroundSpeed} percentage="0">
          <img
            className="plate-image"
            src={plateBg}
            alt="plate section two - background"
          />
        </RellaxWrapper>
      </div>
    </div>
  );
};

export default Plate;
