import React from 'react';
import "./Modal.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ContactFormModal from './ContactFormModal';
import closeIcon from "../../../assets/closeIcon.svg";
import logoText from "../../../assets/logoText.svg";


const MailChimpFormModal = props => {

    // Close modal and reset states to initial
    const handleClose = (value) => {
        props.onChange(value);
    };

    const url = `https://haffinity.us1.list-manage.com/subscribe/post?u=26b69e3b96652ecb44d928c5d&id=eb59a1874f`;

    return (
        <div
            className="modal-container"
            style={{ display: !props.isOpen ? "none" : "block" }}
        >
            <div className="modal-wrapper">
              <div className="upper-controls">
                <div className="close-icon" onClick={() => handleClose(false)}>
                  <img className="close-icon-svg" src={closeIcon} alt="close form" />
                </div>
              </div>
            </div>
            <img src={logoText} className="logo-text-contact" alt="logo" />
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <ContactFormModal
                        status={status}
                        serverMessage={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    )
}

export default MailChimpFormModal;