import React from "react";
import "./Hero.css";
import hero from "../../../assets/hero.jpg";
import Cta from "../../partials/Cta/Cta";

import RellaxWrapper from "react-rellax-wrapper";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Hero = (props) => {
  let isDesktop = props.isDesktop;
  let heroBannerSpeed = isDesktop ? "-2" : "0";
  let heroTextSpeed = isDesktop ? "1" : "0";

  return (
    <div className="hero-container">
      <RellaxWrapper speed={heroBannerSpeed} percentage="0.5">
        <img className="hero-banner" src={hero} alt="" />
      </RellaxWrapper>
      <div className="hero-text-container">
        <RellaxWrapper speed={heroTextSpeed}>
          <div className="big-text">A better Digital Menu in an instant</div>
          <div className="small-text">
            Haffinity is a Digital Menu designed to improve your customers' experience.{" "}
          </div>
          <Cta text={props.ctaText} />
        </RellaxWrapper>
      </div>
    </div>
  );
};

export default Hero;
