import React from "react";
import featureOne from "../../../assets/feature-one.jpg";
import Cta from "../../partials/Cta/Cta";

import "./Design.css";

import RellaxWrapper from "react-rellax-wrapper";

const Design = (props) => {
  let isDesktop = props.isDesktop;
  let designToSeduceBgSpeed = isDesktop ? "-0.7" : "0";

  return (
    <div className="design-container">
      <div className="hand-phone-img-mask">
        <RellaxWrapper speed={designToSeduceBgSpeed} percentage="1">
          <img className="hand-phone-img" src={featureOne} alt="" />
        </RellaxWrapper>
      </div>
      <div className="features-card">
        <div className="section-header card-header">Excite your customers with delightful pictures.</div>
        <p className="section-sub-header card-sub-header">
        Your customers won’t need to read your menu, they will just look at it.
        </p>
        <p className="card-text">
        As a restaurant owner, you know that food plating and presentation are crucial to the guest experience. 
        With Haffinity, your customers will be delighted by large product images of your dishes, 
        and they'll be able to make their choices quickly and easily, so your staff will have more time to focus on what they do best.
        </p>
        <Cta text={props.ctaText} />
      </div>
    </div>
  );
};

export default Design;
