import React from "react";
import "./Intro.css";
import pattern from "../../../assets/haffinity-background-design.jpg";
import logoText from "../../../assets/logoText.svg";

const Intro = () => {
  return (
    <div className="intro-container">
      <div className="intro-background"></div>
      <img src={pattern} className="loading" alt="loading background"></img>
      <img src={logoText} className="logo-text" alt="loading logo"></img>
    </div>
  );
};

export default Intro;
