import React from "react";
import Intro from "./Components/sections/0-Intro/Intro";
import Header from "./Components/partials/Header/Header";
import Hero from "./Components/sections/1-Hero/Hero";
import Prototype from "./Components/sections/2-Prototype/Prototype";
import Process from "./Components/sections/3-Process/Process";
import Design from "./Components/sections/4-Design/Design";
import Plate from "./Components/sections/5-Plate/Plate";
import IpadPreview from "./Components/sections/7-IpadPreview/IpadPreview";
import DashboardView from "./Components/sections/8-DashboardView/DashboardView";
import Advantages from "./Components/sections/9-Advantages/Advantages";
import Opportunities from "./Components/sections/11-Opportunities/Opportunities";
import Footer from "./Components/partials/Footer/Footer";
import "./index.css";
import MailChimpForm from "./Components/sections/10-Contact/MailChimpForm";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isDesktop: window.innerWidth >= 1240,
      viewPortSize: window.innerWidth,
      viewPortHeight: window.innerHeight,
      ctaText: "Request a demo",
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentWillMount() {
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({
      viewPortSize: window.innerWidth,
      viewPortHeight: window.innerHeight,
      isDesktop: window.innerWidth >= 1240,
    });
  }

  timer = setTimeout(() => {
    this.setState({
      isLoading: false,
    });
  }, 5800);

  render() {
    if (this.state.isLoading) {
      return <Intro />;
    } else {
      return (
        <div className="App">
          <Header viewPortSize={this.state.viewPortSize} />
          <Hero ctaText={this.state.ctaText} isDesktop={this.state.isDesktop} />
          <Prototype isDesktop={this.state.isDesktop} />
          {/* <Graphic isDesktop={this.state.isDesktop} /> */}
          <Process
            isDesktop={this.state.isDesktop}
            viewPortSize={this.state.viewPortSize}
            viewPortHeight={this.state.viewPortHeight}
          />
          <Design
            ctaText={this.state.ctaText}
            isDesktop={this.state.isDesktop}
          />
          <Plate
            isDesktop={this.state.isDesktop}
            viewportSize={this.state.viewPortSize}
          />
          <IpadPreview
            ctaText={this.state.ctaText}
            isDesktop={this.state.isDesktop}
          />
          <DashboardView />
          <Advantages isDesktop={this.state.isDesktop} />
          <MailChimpForm isDesktop={this.state.isDesktop}/>
          <Opportunities
            viewportSize={this.state.viewPortSize}
            isDesktop={this.state.isDesktop}
          />
          <Footer isDesktop={this.state.isDesktop} />
        </div>
      );
    }
  }
}

export default App;
