import React from "react";
import haffinityHand from "../../../assets/haffinity-hand.jpg";
import smile from "../../../assets/smile.svg";
import arrowDown from "../../../assets/arrow-down.svg";
import "./Advantages.css";

import RellaxWrapper from "react-rellax-wrapper";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Advantages = (props) => {
  let isDesktop = props.isDesktop;
  let phonesAndTowerSpeed = isDesktop ? "1.5" : "0";

  return (
    <div className="advantages-container">
      <div className="phones-tower-image-mask">
        <RellaxWrapper speed={phonesAndTowerSpeed} percentage="0.5">
          <img className="phones-and-tower-image" src={haffinityHand} alt="" />
        </RellaxWrapper>
      </div>
      <div className="advantages-lower-container">
        <div className="features-card advantage-card">
          <div className="section-header">
          The most advanced Digital Menu for your business.
          </div>
          <div className="section-sub-header">
            With <strong>Haffinity</strong> you can measure performance and understand your opportunities from every angle.
          </div>
          <ul>
            <li className="advantage-item">
              <span className="advantage-item-number">01</span> Uncover hidden opportunities
            </li>
            <li className="advantage-item">
              <span className="advantage-item-number">02</span> Analyse and understand
            </li>
            <li className="advantage-item">
              <span className="advantage-item-number">03</span> Prioritise improvements
            </li>
            <li className="advantage-item">
              <span className="advantage-item-number">04</span> Track &amp; measure outcomes
            </li>
          </ul>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-offset="290"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="500"
          className="join-container"
        >
          <img className="join-smile" src={smile} alt="" />
          <div className="join-header">Request a demo today</div>
          <div className="join-text">
          We are eager to do business with you.
          </div>
          <img src={arrowDown} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Advantages;
