import React from "react";
import "./ArrowScroll.css";

const ArrowScroll = (props) => {
  return (
    <div className="controls-container">
      <svg
        className="left-circle-arrow"
        data-arrow="left"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        onClick={
          props.sliderProgress <= props.minimalScroll
            ? console.log("minimal point reached")
            : props.handleLeftArrow
        }
        xmlns="http://www.w3.org/2000/svg"
        alt="left arrow"
      >
        <path
          d="M21.9998 13.6667L13.6665 22.0001M13.6665 22.0001L21.9998 30.3334M13.6665 22.0001L30.3332 22.0001M42.8332 22.0001C42.8332 33.506 33.5058 42.8334 21.9998 42.8334C10.4939 42.8334 1.1665 33.506 1.1665 22.0001C1.1665 10.4941 10.4939 1.16675 21.9998 1.16675C33.5058 1.16675 42.8332 10.4941 42.8332 22.0001Z"
          stroke="white"
          strokeOpacity={
            props.sliderProgress <= props.minimalScroll ? "0.5" : "1"
          }
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <svg
        className="right-circle-arrow"
        data-arrow="right"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        onClick={
          props.sliderProgress >= props.maximumScroll
            ? console.log("maximum point reached")
            : props.handleRightArrow
        }
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9998 30.3334L30.3332 22.0001M30.3332 22.0001L21.9998 13.6667M30.3332 22.0001H13.6665M42.8332 22.0001C42.8332 33.506 33.5058 42.8334 21.9998 42.8334C10.4939 42.8334 1.1665 33.506 1.1665 22.0001C1.1665 10.4941 10.4939 1.16675 21.9998 1.16675C33.5058 1.16675 42.8332 10.4941 42.8332 22.0001Z"
          stroke="white"
          strokeWidth="2"
          strokeOpacity={
            props.sliderProgress >= props.maximumScroll ? "0.5" : "1"
          }
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ArrowScroll;
