import React from "react";
import dashboardIpad from "../../../assets/dashboardIpad.png";
import dashboardPhone from "../../../assets/dashboardPhone.png";
import data from "../../../assets/data.svg";
import tracking from "../../../assets/tracking.svg";
import customer from "../../../assets/customer-behaviour.svg";
import ComingSoon from "../../partials/Coming Soon/ComingSoon";
import "./DashboardView.css";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Features = () => {
  return (
    <div className="feature-device-container">
      <div className="devices-container">
        <img
          data-aos="fade-down"
          data-aos-offset="180"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor=".feature-device-container"
          className="s-five-ipad"
          src={dashboardIpad}
          alt=""
        />

        <img
          data-aos="fade-up"
          data-aos-offset="180"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor=".feature-device-container"
          className="s-five-phone"
          src={dashboardPhone}
          alt=""
        />
      </div>
      <div className="feature-text-container">
      <ComingSoon text={"Coming soon"} className="cta" />
        <div className="section-header">
        Real data that you can trust.
        </div>
        <div className="section-sub-header">
        All the answers you need just right there.
        </div>
        <div className="feature-text">
          <strong>Haffinity Workspace</strong> helps restaurant managers take control of their business. 
          Our app collects data and reveals insights, dashboards, and analytics so you 
          can strategically make decisions and improve your customer experience.
        </div>
         <div className="feature-body">
            <div className="feature-halign">
              <div className="feature-icon">
                <img src={customer} alt="Customer behaviour analitics." />
              </div>
              <div>
                <span>Customer behaviour analitics.<br /></span> 
              </div>
            </div>

            <div className="feature-halign">
              <div className="feature-icon">
                <img src={tracking} alt="Menu usage deep tracking." />
              </div>
              <div>
                <span>Menu usage deep tracking.<br /></span>
              </div>
            </div>

            <div className="feature-halign">
              <div className="feature-icon">
                <img src={data} alt="Multi venue data aggregation." />
              </div>
              <div>
                <span>Multi venue data aggregation.<br /></span>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Features;
