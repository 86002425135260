import React, { useState } from "react";
import pattern from "../../../assets/pattern.jpg";
import "./Opportunities.css";
import RellaxWrapper from "react-rellax-wrapper";
import AOS from "aos";
import "aos/dist/aos.css";
import MailChimpFormModal from "../../partials/Modal/MailChimpFormModal";

AOS.init();

const Opportunities = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  // prevent scrolling when modal is open
  const bodyElement = document.getElementsByTagName("body")[0];
  isModalOpen
    ? (bodyElement.style.overflow = "hidden")
    : (bodyElement.style.overflow = "visible");

  function handleChange(newValue) {
    setModalOpen(newValue);
  }

  let isDesktop = props.isDesktop;
  let patternBackgroundSpeed = isDesktop ? "3" : "1";

  return (
    <>
      <div className="opportunities-container">
        <div className="pattern-mask">
          <RellaxWrapper speed={patternBackgroundSpeed} percentage="-0.3">
            <img className="pattern" src={pattern} alt="" />
          </RellaxWrapper>
        </div>
        <div className="opportunities-text-container">
          <div className="opportunities-text-header">
            We look forward to hearing from new talents
          </div>
          <div className="opportunities-text-subheader">
            Haffinity is growing. We always harbour new ideas and we welcome new talents.
          </div>
          <div className="opportunities-text">
            If you are interested in joining Haffinity, giving us a feedback, or investing into our growing startup, please feel free to contact us.
          </div>
          <button className="contact-button" onClick={() => setModalOpen(true)}>
            Contact Haffinity
          </button>
        </div>
      </div>
      <MailChimpFormModal
        screenSize={props.viewportSize}
        isOpen={isModalOpen}
        onChange={handleChange}
      />
    </>
  );
};

export default Opportunities;
