import React, { useState, useRef, useEffect } from "react";
import "./Process.css";
import slide1 from "../../../assets/slide2.jpg";
import slide2 from "../../../assets/slide3.jpg";
import slide3 from "../../../assets/slide4.jpg";
import slide4 from "../../../assets/slide5.jpg";
import slide5 from "../../../assets/slide6.jpg";

import arrowPopUpLeft from "../../../assets/arrowPopUpLeft.svg";
import arrowPopUpRight from "../../../assets/arrowPopUpRight.svg";

import ArrowScroll from "../../partials/ArrowScroll/ArrowScroll";

import RellaxWrapper from "react-rellax-wrapper";
import ScrollContainer from "react-indiana-drag-scroll";

const Process = (props) => {
  const sliderContainer = useRef(null);
  const progressContainer = useRef(null);
  const dragPopup = useRef(null);

  const [sliderProgress, setSliderProgress] = useState(0);
  const [sliderOffset, setSliderOffset] = useState(0);
  const [didPopupShow, setPopupShow] = useState(false);

  // Parallax controls
  let isDesktop = props.isDesktop;
  let slidesParallaxSpeed = isDesktop ? "0.8" : "0";
  let slidesProcessOffset = "0";

  const handleHorizontalScroll = (e) => {
    setSliderProgress(
      (e /
        (sliderContainer.current.parentElement.scrollWidth -
          sliderContainer.current.parentElement.clientWidth)) *
      100
    );
    setSliderOffset(e);
  };

  const handleLeftArrow = () => {
    sliderContainer.current.parentElement.scrollTo({
      left: sliderOffset - 355,
      behavior: "smooth",
    });
    setSliderOffset(sliderOffset - 355);
  };

  const handleRightArrow = () => {
    sliderContainer.current.parentElement.scrollTo({
      left: sliderOffset + 355,
      behavior: "smooth",
    });
    setSliderOffset(sliderOffset + 355);
  };

  const handleMouseDown = () => {
    progressContainer.current.style.opacity = 1;
    progressContainer.current.style.transition = `opacity 0.2s ease-in-out`;
    isDesktop
      ? (sliderContainer.current.parentElement.style.transform = "scale(0.97)")
      : (sliderContainer.current.parentElement.style.transform = "scale(1)");
  };

  const handleMouseUp = () => {
    progressContainer.current.style.opacity = 0;
    progressContainer.current.style.transition = `opacity 0.4s linear 5s`;
    sliderContainer.current.parentElement.style.transform = "scale(1)";
  };

  const handleDragPopUp = (show) => {
    if (didPopupShow) {
      dragPopup.current.style.display = "none";
    } else {
      if (isDesktop) {
        if (show) {
          popupFadeIn();
          popupFadeOut(3);
          setPopupShow(true);
        }
      }
    }
  };

  const popupFadeIn = () => {
    dragPopup.current.style.opacity = 1;
    dragPopup.current.style.transition = `opacity 0.3s ease-in-out`;
  };

  const popupFadeOut = (timer) => {
    timer = timer * 1000;
    setTimeout(() => {
      dragPopup.current.style.opacity = 0;
      dragPopup.current.style.transition = `opacity 0.7s ease-in-out `;
    }, timer);
  };

  useEffect(() => {
    setSliderOffset(sliderContainer.current.parentElement.scrollLeft);

    setSliderProgress(
      (sliderContainer.current.parentElement.scrollLeft /
        (sliderContainer.current.parentElement.scrollWidth -
          sliderContainer.current.parentElement.clientWidth)) *
      100
    );

    sliderContainer.current.parentElement.addEventListener("mouseenter", () => {
      handleDragPopUp(true);
    });

    sliderContainer.current.parentElement.addEventListener("mouseleave", () => {
      handleDragPopUp(false);
    });
  });

  return (
    <div className="Process-container">
      <div className="popup-arrow-container" ref={dragPopup}>
        <div className="arrow-popup-left">
          <img src={arrowPopUpLeft} alt="Arrow pop up" />
        </div>
        <div className="popup-text">Drag to scroll</div>
        <div className="arrow-popup-right">
          <img src={arrowPopUpRight} alt="Arrow pop up" />
        </div>
      </div>
      <div className="process-text-container">
        <div className="section-header">
          How it works
        </div>
      </div>
      <ScrollContainer
        onStartScroll={handleMouseDown}
        onEndScroll={handleMouseUp}
        className="process-slider-container"
        vertical={false}
        onScroll={handleHorizontalScroll}
      >
        <div className="process-slider-item first-item" ref={sliderContainer}>
          <div className="process-slide-mask">
            <RellaxWrapper
              speed={slidesParallaxSpeed}
              percentage={slidesProcessOffset}
            >
              <img
                src={slide1}
                className="process-slide-image"
                alt="step one haffinity process"
              />
            </RellaxWrapper>
          </div>

          <div className="process-slide-text">
          Scan the <strong>Haffinity Table Tag</strong>.
          </div>
        </div>

        <div className="process-slider-item">
          <div className="process-slide-mask">
            <RellaxWrapper
              speed={slidesParallaxSpeed}
              percentage={slidesProcessOffset}
            >
              <img
                src={slide2}
                className="process-slide-image"
                alt="step two haffinity process"
              />
            </RellaxWrapper>
          </div>
          <div className="process-slide-text">
          Browse the Restaurant’s menu.
          </div>
        </div>

        <div className="process-slider-item">
          <div className="process-slide-mask">
            <RellaxWrapper
              speed={slidesParallaxSpeed}
              percentage={slidesProcessOffset}
            >
              <img
                src={slide3}
                className="process-slide-image"
                alt="step three haffinity process"
              />
            </RellaxWrapper>
          </div>
          <div className="process-slide-text">
          Shortlist your favourite dishes.
          </div>
        </div>

        <div className="process-slider-item">
          <div className="process-slide-mask">
            <RellaxWrapper
              speed={slidesParallaxSpeed}
              percentage={slidesProcessOffset}
            >
              <img
                src={slide4}
                className="process-slide-image"
                alt="step four haffinity process"
              />
            </RellaxWrapper>
          </div>
          <div className="process-slide-text">
          Place your order with the staff.
          </div>
        </div>
      </ScrollContainer>
      <ArrowScroll
        minimalScroll={3}
        maximumScroll={95}
        sliderProgress={sliderProgress}
        handleLeftArrow={handleLeftArrow}
        handleRightArrow={handleRightArrow}
      />
      <div className="progress-bar-wrapper" ref={progressContainer}>
        <div
          className="progress-bar"
          style={{ width: `${sliderProgress}%` }}
        ></div>
      </div>
    </div>
  );
};
// };
export default Process;
