import React from "react";
import ipad from "../../../assets/ipad.jpg";
import Cta from "../../partials/Cta/Cta";

import "./IpadPreview.css";
import RellaxWrapper from "react-rellax-wrapper";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const IpadPreview = (props) => {
  let isDesktop = props.isDesktop;
  let ipadBackgroundSpeed = isDesktop ? "0.8" : "0";

  return (
    <div className="ipad-preview-container">
      <div className="ipad-preview-mask">
        <RellaxWrapper speed={ipadBackgroundSpeed} percentage="0.45">
          <img
            className="ipad-preview-image"
            src={ipad}
            alt="ipad showing dashboard"
          />
        </RellaxWrapper>
      </div>

      <div className="features-card ipad-preview-card">
        <div className="section-header card-header">Manage your menus like never before.</div>
        <p className="section-sub-header card-sub-header">
        Update anything, at anytime, anywhere.
        </p>
        <p className="card-text">
        <strong>Haffinity Workspace</strong> saves time and money, enabling you to manage your menu without needing costly re-printing. 
        You can update your menu anywhere you are in a few clicks and push updates live to all your restaurants.
        </p>
        <Cta text={props.ctaText} />
      </div>
    </div>
  );
};

export default IpadPreview;
