export const validationStyle = {
  error: "1px solid red",
  valid: "1px solid #b3b3b3",
};

export const validateEmail = (e) => {
  const validEmailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (validEmailRegex.test(e.value) === false) {
    e.style.border = validationStyle.error;
    return false;
  } else {
    e.style.border = validationStyle.valid;
    return true;
  }
};

export const validateString = (e) => {
  if (e.value.length < 2) {
    e.style.border = validationStyle.error;
    return false;
  } else {
    e.style.border = validationStyle.valid;
    return true;
  }
};

export const validateEmailOnClick = (value) => {
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  if (validEmailRegex.test(value) === false) {
    return false;
  } else {
    return true;
  }
};

export const validatePhoneNumber = (e) => {
  const validPhoneNumber = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;

  if (validPhoneNumber.test(e.value) === false) {
    e.style.border = validationStyle.error;
    return false;
  } else {
    e.style.border = validationStyle.valid;
    return true;
  }
};

export const validatePhoneNumberOnclick = (value) => {
  const validPhoneNumber = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;

  if (validPhoneNumber.test(value) === false) {
    return false;
  } else {
    return true;
  }
};
