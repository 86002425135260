import React from "react";
import "./ComingSoon.css";

const ComingSoon = (props) => {
  return (
    <a href="#" className="coming-soon-cointainer">
      <div className="coming-soon-text">{props.text}</div>
    </a>
  );
};

export default ComingSoon;
