import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import check from "../../../assets/check-circle.svg";
import arrowRight from "../../../assets/arrow-right.svg";
import "./Contact.css";
import AOS from "aos";
import "aos/dist/aos.css";

import { validateEmail, validateString } from "./simpleValidation";

AOS.init();

const Contact = ({ status, serverMessage, onValidated }) => {

  const [businessEmail, setBusinessEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [buttonText, setButtonText] = useState("Book a demo");
  const [sendingMail, setSendingMail] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const contactForm = useRef(null);

  const resetForm = (time) => {
    time = time * 1000;
    setTimeout(() => {
      setBusinessEmail("");
      setName("");
      setSurname("");
      setCompanyName("");
      setRole("");
      setMessage("");
    }, time);
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
    if (value) {
      setDisableBtn(false);
      setIsVerified(true);
    } else {
      setDisableBtn(true);
      setIsVerified(false);
    }
  };

  const recaptchaRef = React.createRef();

  useEffect(() => {
    if(status === "success") {
      setButtonText("Completed!");
      setThankYouMessage("Thank you! We will be in touch shortly.")
      resetForm(0)
      setDisableBtn(true)
    }
  }, [status])

  const onSubmit = async (e) => {
    e.preventDefault();

    setButtonText("Sending...");
    setSendingMail(true);

    businessEmail &&
    name &&
    surname &&
    businessEmail.indexOf("@") > -1 &&
    companyName &&
    role &&
    onValidated({
        EMAIL: businessEmail,
        MERGE1: name,
        MERGE2: surname,
        MERGE6: companyName,
        MERGE7: role,
        MERGE8: message
    });
  };

  return (
    <div id="waitlist" className="contact-container">
      <div className="left-panel">
        <div className="contact-card">
          <div className="contact-card-text-container">
            <div
              data-aos="fade-down"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="top-bottom"
              data-aos-once="false"
              className="contact-card-header"
            >
              Get in touch!
            </div>
            <ul className="contact-card-list">
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="false"
                className="contact-item"
              >
                <img className="circle-check" src={check} alt="" />
                <li>We will contact you to schedule a demo at your most convenient time.</li>
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="false"
                className="contact-item"
              >
                <img className="circle-check" src={check} alt="" />
                <li>
                Get a first look at Haffinity.
                </li>
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="false"
                className="contact-item"
              >
                <img className="circle-check" src={check} alt="" />
                <li>
                Understand how it works and how it can help your business.
                </li>
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="false"
                className="contact-item"
              >
                <img className="circle-check" src={check} alt="" />
                <li>No purchase obligation.</li>
              </div>
              <div
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-anchor-placement="top-bottom"
                data-aos-once="false"
                className="contact-item"
              >
                <img className="circle-check" src={check} alt="" />
                <li>No lock-in contracts.</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="right-panel">
        <form className="contact-form" onSubmit={onSubmit} ref={contactForm}>
          <label className="contact-label" htmlFor="business-email">
            Business Email *
          </label>
          <input
            className="contact-input-fields"
            type="mail"
            name="MERGE0"
            id="business-email"
            value={businessEmail}
            onChange={(e) => setBusinessEmail(e.target.value)}
            onKeyUp={(e) => validateEmail(e.target)}
            required
          />

          <div className="name-row">
            <div className="flex-column">
              <label className="contact-label" htmlFor="name">
                First Name *
              </label>
              <input
                className="contact-input-fields"
                type="text"
                name="MERGE1"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyUp={(e) => validateString(e.target)}
                minLength="2"
                required
              />
            </div>
            <div className="flex-column">
              <label className="contact-label" htmlFor="surname">
                Last Name *
              </label>
              <input
                className="contact-input-fields"
                type="text"
                name="MERGE2"
                id="surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                onKeyUp={(e) => validateString(e.target)}
                minLength="2"
                required
              />
            </div>
          </div>

          <label className="contact-label" htmlFor="company">
            Company *
          </label>
          <input
            className="contact-input-fields"
            type="text"
            name="MERGE6"
            id="company"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            onKeyUp={(e) => validateString(e.target)}
            minLength="2"
            required
          />

          <label className="contact-label" htmlFor="role">
            Role *
          </label>
          <input
            className="contact-input-fields"
            type="text"
            name="MERGE7"
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            onKeyUp={(e) => validateString(e.target)}
            minLength="2"
            required
          />

          <label className="contact-label" htmlFor="message">
            Message (Optional)
          </label>
          <textarea
            rows={6}
            cols={20}
            className="contact-input-fields message-area"
            name="MERGE8"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setMessage(message + "\n");
              }
            }}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfX3mIaAAAAAB8kT6gPQIeAdt3tGSdfBnHAtcFi"
            onChange={onChange}
            theme="dark"
            size="normal"
          />
          <button
            className="contact-button"
            disabled={disableBtn}
            style={{
              background: disableBtn ? "#fafafa" : "#ccffcc"
            }}
          >
            {buttonText}
            <img
              className="arrow-right"
              src={arrowRight}
              alt="arrow right"
              style={{ opacity: sendingMail ? "0" : "1" }}
            />
          </button>
          <p className="contact-thank-you-message contact-label">
            {thankYouMessage}
          </p>
        </form>
      </div>
    </div>
  );
};

export default Contact;
