import React from "react";
import logoText from "../../../assets/logoText.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer-container">
      <div className="left">
        <img src={logoText} alt="" />
        <div className="coming-text">is Available on iOS &amp; Android </div>
      </div>
      <div className="right">
        <p>Haffinity Pty Ltd • Copyright 2022 • All rights are reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
