import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import arrowRight from "../../../assets/arrow-right.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Modal.css"

import { validateEmail, validateString } from "../../sections/10-Contact/simpleValidation";

AOS.init();

const ContactFormModal = ({ status, serverMessage, onValidated }) => {

  const [businessEmail, setBusinessEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonText, setButtonText] = useState("Send");
  const [sendingMail, setSendingMail] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [thankYouMessage, setThankYouMessage] = useState("");

  const contactForm = useRef(null);

  const resetForm = (time) => {
    time = time * 1000;
    setTimeout(() => {
      setBusinessEmail("");
      setName("");
      setSurname("");
      setPhoneNumber("");
      setMessage("");
    }, time);
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
    if (value) {
      setDisableBtn(false);
      setIsVerified(true);
    } else {
      setDisableBtn(true);
      setIsVerified(false);
    }
  };

  const recaptchaRef = React.createRef();

  useEffect(() => {
    if(status === "success") {
      setButtonText("Sent!");
      resetForm(0)
      setThankYouMessage("Thank you! We will be in touch shortly.")
      setDisableBtn(true)
    }
  }, [status])

  const onSubmit = async (e) => {
    e.preventDefault();

    setButtonText("Sending...");
    setSendingMail(true);

    businessEmail &&
    name &&
    surname &&
    businessEmail.indexOf("@") > -1 &&
    onValidated({
        EMAIL: businessEmail,
        MERGE1: name,
        MERGE2: surname,
        MERGE8: message,
        MERGE4: phoneNumber
    });
  };

  return (
    <form className="form-stepper-wrapper" onSubmit={onSubmit} ref={contactForm}>
          <div className="name-row">
            <div className="flex-column">
              <label className="contact-label" htmlFor="name">
                First Name *
              </label>
              <input
                className="contact-input-fields"
                type="text"
                name="MERGE1"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyUp={(e) => validateString(e.target)}
                minLength="2"
                required
              />
            </div>
            <div className="flex-column">
              <label className="contact-label" htmlFor="surname">
                Last Name *
              </label>
              <input
                className="contact-input-fields"
                type="text"
                name="MERGE2"
                id="surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                onKeyUp={(e) => validateString(e.target)}
                minLength="2"
                required
              />
            </div>
          </div>

          <label className="contact-label" htmlFor="business-email">
            Email *
          </label>
          <input
            className="contact-input-fields"
            type="mail"
            name="MERGE0"
            id="business-email"
            value={businessEmail}
            onChange={(e) => setBusinessEmail(e.target.value)}
            onKeyUp={(e) => validateEmail(e.target)}
            required
          />

          <label className="contact-label" htmlFor="phoneNumber">
            Phone Number (Optional)
          </label>
          <input
            className="contact-input-fields"
            type="text"
            name="MERGE4"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          <label className="contact-label" htmlFor="message">
            Your message to us (Optional)
          </label>
          <textarea
            rows={6}
            cols={20}
            className="contact-input-fields message-area"
            name="MERGE8"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setMessage(message + "\n");
              }
            }}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfX3mIaAAAAAB8kT6gPQIeAdt3tGSdfBnHAtcFi"
            onChange={onChange}
            theme="dark"
            size="normal"
          />
          <button
            className="contact-button"
            disabled={disableBtn}
            style={{
              background: disableBtn ? "#fafafa" : "#ccffcc"
            }}
          >
            {buttonText}
            <img
              className="arrow-right"
              src={arrowRight}
              alt="arrow right"
              style={{ opacity: sendingMail ? "0" : "1" }}
            />
          </button>
          <p className="contact-thank-you-message contact-label">
            {thankYouMessage}
          </p>
    </form>
  );
};

export default ContactFormModal;
