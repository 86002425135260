import React from "react";
import "./Prototype.css";

import girls from "../../../assets/girls.png";
import leaf from "../../../assets/leaf.svg";
import hearts from "../../../assets/hearts.svg";
import trend from "../../../assets/trend.svg";

import smallImg from "../../../assets/section1-image2.png";

import RellaxWrapper from "react-rellax-wrapper";

const Prototype = (props) => {
  let isDesktop = props.isDesktop;
  let prototypeTextSpeed = isDesktop ? "-2.5" : "0";
  let guysAtTheTableSpeed = isDesktop ? "2" : "0";
  let prototypeAppSpeed = isDesktop ? "-0.6" : "0";

  return (
    <div className="prototype-container">
      <div className="prototype-text-container">
        <RellaxWrapper speed={prototypeTextSpeed} percentage="0">
          <div className="section-header">
            Haffinity is the best cost-effective way to offer your customers an up-to-date, interactive, and personalised dining experience.{" "}
          </div>
          <div className="section-sub-header">
            Save money, time, and the environment by switching to Haffinity.
          </div>
          <div className="prototype-body">
            <div className="prototype-halign">
              <div className="prototype-icon">
                <img src={leaf} alt="It's environmentally friendly" />
              </div>
              <div>
                <span>It's environmentally friendly.<br /></span>
              </div>
            </div>

            <div className="prototype-halign">
              <div className="prototype-icon">
                <img src={hearts} alt="It has a captivating and easy-to-use interface." />
              </div>
              <div>
                <span>It has a captivating and easy-to-use interface.<br /></span>
              </div>
            </div>

            <div className="prototype-halign">
              <div className="prototype-icon">
                <img src={trend} alt="It streamlines your staff operations." />
              </div>
              <div>
                <span> It streamlines your staff operations.<br /></span>
              </div>
            </div>
          </div>
        </RellaxWrapper>
      </div>
      <div className="prototype-images">
        <div className="girls-mask">
          <RellaxWrapper speed={guysAtTheTableSpeed} percentage="-0.2">
            <img className="girls-image" src={girls} alt="in use device" />
          </RellaxWrapper>
        </div>
        <RellaxWrapper speed={prototypeAppSpeed} percentage="1">
          <img
            className="smaller-img"
            src={smallImg}
            alt="application preview"
          />
        </RellaxWrapper>
      </div>
    </div>
  );
};

export default Prototype;
