import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Contact from './Contact';

const MailChimpForm = props => {
    const url = `https://haffinity.us1.list-manage.com/subscribe/post?u=26b69e3b96652ecb44d928c5d&id=eb59a1874f`;

    return (

        <div className="mc__form-container">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <Contact
                        status={status}
                        serverMessage={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>

    )
}

export default MailChimpForm;